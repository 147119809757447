<template>
    <div style="height: 100%; min-height: 100%">
        <b-card no-body>
            <div class="row text-center hidden-md hidden-lg">
                <div class="col-xs-12">
                    <div class="font-weight-bold text-primary big-title">
                        {{ domain }}
                    </div>
                </div>
                <div class="col-xs-12">
          <span
                  v-if="whois.status == `unavailable` && whois.premium == false"
                  class="title text-warning"
          >
            Spiacenti, il dominio cercato è occupato, se è tuo
            trasferiscilo.</span
          >
                    <span
                            v-if="whois.status == `available` && whois.premium == false"
                            class="title text-success hidden"
                    >Disponibile e pronto per la registrazione.</span
                    >
                    <span v-if="whois.premium == true" class="title text-info"
                    >Dominio premium.
            <a href="/front/contatti" target="_blank">Contattaci.</a></span
                    >
                </div>
                <div class="col-xs-12">
          <span v-if="whois.status == `unavailable`" class="big-title">
            Dominio occupato</span
          >
                    <span v-if="whois.status == `available`" class="big-title"
                    >Dominio disponibile</span
                    >
                </div>
                <hr />
            </div>
            <div class="table-responsive hidden-xs hidden-sm divMainDomainTableTop">
                <table class="table mb-0 mainDomainTableTop" v-if="domain">
                    <tbody>
                    <tr>
                        <th
                                scope="row"
                                colspan="2"
                                class="font-weight-bold text-primary big-title"
                        >
                            {{ domain }}
                        </th>
                        <td
                                class="text-right"
                                :class="[
                  { 'text-success': whois.status == `available` },
                  { 'text-warning': whois.status == `unavailable` },
                  { 'text-info': whois.premium == true },
                ]"
                        >
                <span v-if="whois.status == `unavailable`" class="big-title">
                  Dominio occupato</span
                >
                            <span v-if="whois.status == `available`" class="big-title"
                            >Dominio disponibile</span
                            >
                            <span v-if="whois.premium == true" class="big-title"
                            >Dominio premium</span
                            >
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </b-card>
        <h2 class="text-primary title hidden-xs hidden-sm" style="margin-top: 3rem">
            Dai un'occhiata a questi suggerimenti ({{ checkboxDomain.length }}
            selezionati)
        </h2>
        <div class="text-primary title text-center hidden-md hidden-lg">
            <h2>
                Dai un'occhiata a questi suggerimenti ({{ checkboxDomain.length }}
                selezionati)
            </h2>
            <hr />
        </div>
        <b-card
                no-body
                class="h-100 mb-5"
                v-if="prices.data && prices.data.length"
                border-variant="dark"
        >
            <div class="table-responsive suggestListDomain" style="margin: 2rem auto">
                <table
                        class="table table-hover mb-0"
                        v-if="prices.data && prices.data.length"
                >
                    <tbody>
                    <tr
                            v-for="(price, index) in prices.data"
                            :key="price.extension"
                            :class="[
                {
                  info: checkboxDomain.includes(sld + price.extension),
                },
              ]"
                    >
                        <th scope="row" class="title">{{ sld }}{{ price.extension }}</th>
                        <td
                                v-if="
                  Object.entries(resultsWhois).length &&
                  resultsWhois[sld + price.extension]
                "
                                class="title hidden-xs hidden-sm"
                                :class="[
                  {
                    'text-success':
                      whoisDomainComputed(sld + price.extension).status ==
                      `available`,
                  },
                  {
                    'text-warning':
                      whoisDomainComputed(sld + price.extension).status ==
                      `unavailable`,
                  },
                ]"
                        >
                            {{
                            whoisDomainComputed(sld + price.extension).status ==
                            `available`
                            ? `Disponibile`
                            : `Occupato`
                            }}
                        </td>
                        <td v-else class="hidden-xs hidden-sm"></td>
                        <td
                                class="text-center hidden-xs hidden-sm"
                                v-if="
                  whoisDomainComputed(sld + price.extension).premium == true
                "
                        >
                <span class="badge" style="position: relative; top: 0; right: 0"
                >Dominio premium</span
                >
                        </td>
                        <td v-else class="hidden-xs hidden-sm"></td>
                        <td
                                class="font-weight-bold text-primary text-right title"
                                v-if="
                  Object.entries(resultsWhois).length &&
                  resultsWhois[sld + price.extension]
                "
                        >
                            &euro;
                            <span v-if="whoisDomainComputed(sld + price.extension)">
                  {{
                    whoisDomainComputed(sld + price.extension).status ==
                    `available`
                      ? whoisDomainComputed(sld + price.extension).prices
                          .domainregister
                      : whoisDomainComputed(sld + price.extension).prices
                          .domaintransfer
                  }}</span
                            >
                            <span v-else>{{ price.msetupfee }}</span>
                        </td>
                        <td v-else class="text-right"></td>
                        <td
                                class="text-right"
                                v-if="
                  Object.entries(resultsWhois).length &&
                  resultsWhois[sld + price.extension]
                "
                        >
                            <b-button
                                    v-if="
                    whoisDomainComputed(sld + price.extension).premium == true
                  "
                                    size="sm"
                                    :href="
                    whoisDomainComputed(sld + price.extension).premium == true
                      ? `/front/contatti`
                      : `javascript:void(0)`
                  "
                                    variant="info"
                                    target="_blank"
                            >
                                Contattaci
                            </b-button>
                            <b-button
                                    v-else
                                    size="sm"
                                    :href="
                    whoisDomainComputed(sld + price.extension).premium == true
                      ? `/front/contatti`
                      : `javascript:void(0)`
                  "
                                    :variant="getButtonVariant(sld + price.extension)"
                                    @click="
                    addToList(
                      sld + price.extension,
                      index,
                      whoisDomainComputed(sld + price.extension).status ==
                        `available`
                        ? whoisDomainComputed(sld + price.extension).prices
                            .domainregister
                        : whoisDomainComputed(sld + price.extension).prices
                            .domaintransfer
                    )
                  "
                                    :disabled="
                    whoisDomainComputed(sld + price.extension).premium == true
                  "
                            >
                  <span v-if="checkboxDomain.includes(sld + price.extension)">
                    Rimuovi
                  </span>
                                <span
                                        v-else-if="
                      whoisDomainComputed(sld + price.extension).premium == true
                    "
                                >
                    Contattaci
                  </span>
                                <span v-else>
                    {{
                      whoisDomainComputed(sld + price.extension).status ==
                      `available`
                        ? `Registra`
                        : `Trasferisci`
                    }}
                  </span>
                            </b-button>
                        </td>
                        <td v-else class="text-right contentLoading">
                            <i class="fas fa-cog fa-spin"></i>
                        </td>
                    </tr>
                    <tr style="background-color: transparent">
                        <td id="tdContentDomainInCart">
                            <span id="TitleDomainInCaryt">Domini nel carrello:</span>
                            <i class="material-icons" id="cartSummaryIcon">shopping_cart</i>
                        </td>
                        <td colspan="4">
                            <ul
                                    class="
                    pagination pagination-sm
                    text-lowercase
                    d-inline-flex
                    p-0
                    m-0
                    pull-right
                  "
                                    style="margin-top: 15px"
                                    v-if="prices.data && prices.data.length"
                            >
                                <li
                                        class="page-item"
                                        :class="[{ disabled: !prices.prev_page_url }]"
                                >
                                    <a
                                            class="page-link"
                                            href="javascript:void(0)"
                                            @click="getPrices(prices.prev_page_url)"
                                    >
                                        <i class="fas fa-step-backward"></i>
                                    </a>
                                </li>
                                <li class="page-item disabled">
                                    <a class="page-link text-dark" href="javascript:void(0)"
                                    >{{ prices.current_page }} di {{ prices.last_page }}</a
                                    >
                                </li>
                                <li
                                        class="page-item"
                                        :class="[{ disabled: !prices.next_page_url }]"
                                >
                                    <a
                                            class="page-link"
                                            href="javascript:void(0)"
                                            @click="getPrices(prices.next_page_url)"
                                    >
                                        <i class="fas fa-step-forward"></i>
                                    </a>
                                </li>
                            </ul>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </b-card>
        <b-card
                v-if="checkboxDomain.length"
                style=""
                class="hidden-xs hidden-sm listSummaryDomain"
        >
            <b-badge
                    variant="dark"
                    style="margin-right: 0.8rem; top: 0; right: 0"
                    v-for="d in checkboxDomain"
                    :key="d"
            >{{ d }}</b-badge
            >
        </b-card>
        <b-card no-body style="margin-bottom: 5rem">
            <div class="row text-center hidden-md hidden-lg">
                <div class="col-xs-12">
                    <u>Prezzi annuali, IVA esclusa</u>
                </div>
                <div class="col-xs-12">
                    <div
                            class="title"
                            v-if="checkboxDomain.length"
                            style="margin-top: 2rem"
                    >
                        <b>Totale:</b>
                        <span class="big-title font-weight-bolder text-primary"
                        >&euro; {{ getTotal() }}</span
                        >
                    </div>
                    <div v-else></div>
                </div>
                <div class="col-xs-12">
                    <b-button
                            variant="default"
                            squared
                            size="lg"
                            block
                            style="margin-top: 2rem"
                            :disabled="
              loading ||
              !checkboxDomain.length ||
              Object.entries(resultsWhois).length < 5
            "
                            class="bottone shadow-sm"
                            @click="addToCart()"
                    >
                        Prosegui
                        <!--Prosegui e vai al carrello ({{ checkboxDomain.length }})-->
                    </b-button>
                </div>
            </div>
            <div class="table-responsive hidden-xs hidden-sm">
                <table class="table table-outlined mb-0" v-if="domain">
                    <tbody>
                    <tr v-if="checkboxDomain.length" class="totalRow">
                        <td
                                class="text-right title"
                                colspan="3"
                                v-if="checkboxDomain.length"
                        >
                            <b>Totale:</b>
                            <span class="big-title font-weight-bolder text-primary"
                            >&euro; {{ getTotal() }}</span
                            >
                        </td>
                        <td v-else colspan="3"></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <br />
                            <u>Prezzi annuali, IVA esclusa</u>
                        </td>
                        <td class="text-right">
                            <br />
                            <b-button
                                    variant="default"
                                    squared
                                    size="lg"
                                    :disabled="
                    loading ||
                    !checkboxDomain.length ||
                    Object.entries(resultsWhois).length < 5
                  "
                                    class="bottone shadow-sm"
                                    @click="addToCart()"
                            >
                                Prosegui
                                <!--Prosegui e vai al carrello ({{ checkboxDomain.length }})-->
                            </b-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: "DomainSuggestions",
        components: {},
        props: ["sld", "tld", "domain", "whois", "price"],
        data() {
            return {
                checkboxDomain: [],
                prices: {},
                suggestions: {},
                loading: false,
                token: null,
                suggestionsToShow: 10,
                totalSuggestions: 0,
                useSpinnerWHMCS: false,
                resultsWhois: {},
                total: 0.0,
                currentWhois: {},
            };
        },
        methods: {
            getButtonVariant(domain) {
                let variant = `outline-default`;

                if (this.checkboxDomain.includes(domain)) {
                    variant = `default`;
                }

                if (
                    this.whoisDomainComputed(domain).status == `available` &&
                    !this.checkboxDomain.includes(domain)
                ) {
                    variant = `success`;
                }

                if (
                    this.whoisDomainComputed(domain).status == `unavailable` &&
                    !this.checkboxDomain.includes(domain)
                ) {
                    variant = `warning`;
                }

                if (
                    this.whoisDomainComputed(domain).premium == true &&
                    !this.checkboxDomain.includes(domain)
                ) {
                    variant = `info`;
                }

                return variant;
            },
            whoisDomainComputed(domain) {
                return this.resultsWhois[domain] ?? [];
            },
            whoisDomain(domain, tld, index = null) {
                axios
                    .post("/cart/whois", {
                        domain: domain,
                        tld: tld,
                    })
                    .then((response) => {
                        this.resultsWhois[domain] = response.data;
                        this.$forceUpdate();
                    })
                    .catch((errors) => console.log(errors));
            },
            whoisDomainCurrent(domain, tld, index = null) {
                this.loading = true;
                axios
                    .post("/cart/whois", {
                        domain: domain,
                        tld: tld,
                    })
                    .then((response) => {
                        this.currentWhois = response.data;

                        if (!this.currentWhois.premium) {
                            this.checkboxDomain.push(domain);

                            let price;

                            if (this.currentWhois.status == `available`) {
                                price = this.currentWhois.prices.domainregister;
                            } else if (this.currentWhois.status == `unavailable`) {
                                price = this.currentWhois.prices.domaintransfer;
                            } else {
                                price = 0.0;
                            }

                            this.total = parseFloat(this.total) + parseFloat(price);

                            this.scrollToTop(
                                `validationMessage`,
                                `Dominio <strong>` + domain + `</strong> aggiunto`,
                                "success"
                            );

                            this.loading = false;

                            this.$forceUpdate();
                        }
                    })
                    .catch((errors) => console.log(errors));
            },
            getWHMCSToken() {
                let token;
                this.loading = true;
                $(".spinner_indominio").show();

                axios
                    .post("/billing/index.php", {})
                    .then((response) => {
                        token = $(response.data).find('input[name="token"]').val();
                        // token = null;
                        this.token = token;
                        this.domainNameSpinnig();
                        this.loading = false;
                        $(".spinner_indominio").fadeOut();
                    })
                    .catch((errors) => console.log(errors));

                return token;
            },
            domainNameSpinnig() {
                let token = this.token;
                let domain = this.domain;
                let results;

                if (this.token) {
                    var settings = {
                        async: true,
                        crossDomain: true,
                        url: "/billing/index.php?rp=/domain/check",
                        method: "POST",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                            "cache-control": "no-cache",
                        },
                        data: {
                            domain: domain,
                            token: token,
                            type: "suggestions",
                        },
                    };

                    $.ajax(settings)
                        .then((response) => {
                            results = response.result;
                            // console.log(results);
                        })
                        .done(() => {
                            this.suggestions = results;
                            this.totalSuggestions = Object.entries(this.suggestions).length;
                            this.$forceUpdate();
                        });
                } else {
                    console.error(
                        "Token WHMCS non disponibile. Caricato spinner alternativo."
                    );
                    this.getPrices();
                }
            },
            getPrices(page_url) {
                this.loading = true;
                // $(".spinner_indominio").show();
                let loader = this.$loading.show({
                    container: null,
                    canCancel: false,
                    color: "#ffbf00",
                    backgroundColor: "#000000",
                    lockScroll: true,
                    isFullPage: true,
                    height: 128,
                    width: 128,
                    loader: "spinner",
                });
                page_url = page_url || "/cart/get-prices";
                axios
                    .post(page_url, {
                        formato: this.formato,
                    })
                    .then((response) => {
                        // console.log(response.data);
                        this.prices = response.data;
                        this.loading = false;
                        loader.hide();
                        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                        // $(".spinner_indominio").fadeOut();
                    })
                    .catch((errors) => console.log(errors));
            },
            addToList(domain, index, price) {
                // console.log(price);

                // this.total = parseFloat(this.total).toFixed(2);
                let total;

                if (this.checkboxDomain.includes(domain)) {
                    this.checkboxDomain.splice(this.checkboxDomain.indexOf(domain), 1);
                    total = parseFloat(this.total) - parseFloat(price);
                    this.scrollToTop(
                        `validationMessage`,
                        `Dominio <strong>` + domain + `</strong> rimosso`,
                        "warning"
                    );
                } else {
                    this.checkboxDomain.push(domain);
                    total = parseFloat(this.total) + parseFloat(price);
                    this.scrollToTop(
                        `validationMessage`,
                        `Dominio <strong>` + domain + `</strong> aggiunto`,
                        "success"
                    );
                }

                this.total = total;

                this.$forceUpdate();
            },
            getTotal() {
                return this.total.toFixed(2);
            },
            async test() {
                try {
                    let res = await axios({
                        url: "/cart/suggestions",
                        method: "post",
                        timeout: 8000,
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: {
                            checkboxDomain: this.checkboxDomain,
                            sld: this.sld,
                            tld: this.tld,
                            domain: this.domain,
                        },
                    });
                    if (res.status == 200) {
                        // test for status you want, etc
                        console.log(res.status);
                    }
                    // Don't forget to return something
                    return res.data;
                } catch (err) {
                    console.error(err);
                }
            },
            addToCart() {
                this.$forceUpdate();

                if (!this.loading) {
                    let domainsAdded = [];
                    this.loading = true;
                    // $(".spinner_indominio").show();
                    let loader = this.$loading.show({
                        container: null,
                        canCancel: false,
                        color: "#ffbf00",
                        backgroundColor: "#000000",
                        lockScroll: true,
                        isFullPage: true,
                        height: 128,
                        width: 128,
                        loader: "spinner",
                    });

                    axios
                        .post("/cart/suggestions", {
                            checkboxDomain: this.checkboxDomain,
                            sld: this.sld,
                            tld: this.tld,
                            domain: this.domain,
                        })
                        .then((response) => {
                            // console.log(response.data);
                            domainsAdded = response.data;

                            // setTimeout(() => {
                            this.loading = false;
                            // $(".spinner_indominio").fadeOut();
                            loader.hide();
                            window.location.replace("/cart");
                            // }, 5000);
                        })
                        .catch((errors) => console.log(errors));
                }
            },
            scrollToTop(validationMessage, stepMessage, alertType) {
                if (validationMessage) {
                    $.notify(
                        {
                            message: stepMessage,
                        },
                        {
                            type: alertType,
                            allow_dismiss: false,
                            showProgressbar: false,
                            newest_on_top: true,
                            delay: 5000,
                            placement: {
                                from: "top",
                                align: "right",
                            },
                        }
                    );
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                } else {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }
            },
        },
        computed: {
            whoisOptions(domain) {
                if (domain && this.resultsWhois.length) {
                    return this.resultsWhois
                        .filter((f) => f.domain == domain)
                        .map((f) => {
                            return { prices: f.prices, status: f.status };
                        });
                } else {
                    return [];
                }
            },
            domainPrice() {
                let available;
                let price;
                let priceDomainRegister;
                let priceDomainTransfer;
                let priceDomainRenew;

                for (let index = 0; index < this.price.length; index++) {
                    const element = this.price[index];

                    if (element.type == `domainregister`) {
                        priceDomainRegister = element.msetupfee;
                    }

                    if (element.type == `domaintransfer`) {
                        priceDomainTransfer = element.msetupfee;
                    }

                    if (element.type == `domainrenew`) {
                        priceDomainRenew = element.msetupfee;
                    }
                }

                switch (this.whois.status) {
                    case `available`:
                        available = true;
                        price = priceDomainRegister;
                        break;

                    case `unavailable`:
                        available = false;
                        price = priceDomainTransfer;
                        break;

                    default:
                        available = false;
                        break;
                }

                return price;
            },
        },
        watch: {
            prices: function () {
                this.prices.data.forEach((element, index) => {
                    if (!this.checkboxDomain.includes(this.sld + element.extension)) {
                        this.loading = true;
                        this.whoisDomain(
                            this.sld + element.extension,
                            element.extension,
                            index
                        );
                        this.loading = false;
                    }
                });
            },
        },
        beforeCreate() {},
        created() {},
        beforeMount() {},
        mounted() {
            this.loading = true;

            // this.checkboxDomain.push(this.domain);
            if (this.useSpinnerWHMCS) {
                this.getWHMCSToken();
            } else {
                this.getPrices();
            }

            this.whoisDomainCurrent(this.domain, `.` + this.tld);

            this.loading = false;

            // this.test().then((res) => console.log(res));
        },
        beforeUnmount() {},
        unmounted() {},
    };
</script>

<!-- <style scoped lang="css" src="bootstrap/dist/css/bootstrap.css"></style> -->
<!--<style scoped lang="css" src="bootstrap-vue/dist/bootstrap-vue.css"></style>-->

<style scoped lang="scss">
    * {
        font-size: 1.8rem !important;
    }



    .badge {
        position: relative !important;
    }

    // .table-responsive {
    //   /* max-height: 100vh; */
    //   margin-bottom: 5rem;
    // }

    .title {
        font-size: 20px !important;
        font-weight: 700 !important;
    }

    .big-title {
        font-size: 30px !important;
        font-weight: 800 !important;
    }

    .text-primary {
        color: #418dca !important;
    }

    .text-warning {
        color: #f76b1c !important;
    }

    .text-success {
        color: rgb(45, 149, 0) !important;
    }
</style>