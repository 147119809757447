
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

import BootstrapVue from "bootstrap-vue";
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)
Vue.use(BootstrapVue);
Vue.use(VueLoading);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.component(
    "domain-suggestions",
    require("./components/domain/Suggestions.vue").default
);

Vue.component(
    "cart-index",
    require("./components/cart/Cart.vue").default
);

Vue.component(
    "associate-hosting",
    require("./components/cart/partials/AssociateHosting.vue").default
);

Vue.component(
    "associate-hosting-mobile",
    require("./components/cart/partials/AssociateHostingMobile.vue").default
);

Vue.component(
    "remove-domain",
    require("./components/cart/partials/RemoveDomain.vue").default
);

Vue.component(
    "remove-hosting",
    require("./components/cart/partials/RemoveHosting.vue").default
);

Vue.component(
    "cart-coupon",
    require("./components/cart/partials/Coupon.vue").default
);

Vue.component(
    "cart-empty",
    require("./components/cart/partials/EmptyCart.vue").default
);

// Vue.component('example-component', require('./components/ExampleComponent.vue'));

const app = new Vue({
    el: '#app'
});
