<template>
  <div class="modal fade modal-associate-hosting" :id="`domain_modal_associate_hosting_${index}`" tabindex="-1"
    role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-lg" role="document">
      <span class="gradient-piece-before-modal"></span>
      <div class="modal-content cart-modal">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row" v-for="(group, i) in productGroups" :key="`group_` + group.pid">
            <div class="col-xs-12 col-sm-12 col-md-4" v-for="product in prodotti.slice(
              i * itemsPerRow,
              (i + 1) * itemsPerRow
            )" :key="`prod_` + product.pid">
              <div v-if="
                !product.booleanOptions.hidden &&
                !product.booleanOptions.retired
              " :class="`offert-box ${product.name.toLowerCase()}`" style="margin-top: 1rem; margin-bottom: 1rem">
                <span class="gradient-piece-before"></span>
                <ul>
                  <li>
                    <h2>
                      <div class="circle">
                        <span class="number" v-if="product.name.toLowerCase() == `one`">{{ 1 }}</span>
                        <span class="number" v-else-if="product.name.toLowerCase() == `five`">{{ 5 }}</span>
                        <span class="number" v-else>{{ 5 }}</span>
                      </div>
                      <span class="offert-title">{{
                          product.name.toLowerCase()
                      }}</span>
                    </h2>
                  </li>
                  <li>
                    <h3>dominio .it gratis</h3>
                  </li>
                  <li>
                    <span v-if="product.name.toLowerCase() == `one`">
                      20 GB</span>
                    <span v-else-if="product.name.toLowerCase() == `five`">
                      50 GB</span>
                    <span v-else> 100 GB</span>
                    spazio web
                  </li>
                  <li>
                    <span v-if="product.name.toLowerCase() == `one`"> 10</span>
                    <span v-else-if="product.name.toLowerCase() == `five`">
                      20</span>
                    <span v-else> Illimitate</span>
                    caselle email
                  </li>
                  <li>
                    <span v-if="product.name.toLowerCase() == `one`"> 2</span>
                    <span v-else-if="product.name.toLowerCase() == `five`">
                      10</span>
                    <span v-else> Illimitati</span>
                    database
                  </li>
                </ul>
                <div class="price-cta text-center">
                  <p>
                    &euro;
                    <span v-if="product.paytype != `onetime`">
                      {{ Math.trunc(product.pricing.EUR.annually) }}</span>
                    <span v-else>
                      {{ Math.trunc(product.pricing.EUR.monthly) }}</span><span class="minor-price"
                      v-if="product.paytype != `onetime`">,{{ product.pricing.EUR.annually.split(".")[1] }}</span>
                    <span class="minor-price" v-else>,{{ product.pricing.EUR.monthly.split(".")[1] }}</span>
                    <br />
                    <span class="all-inclusive">tutto incluso</span>
                  </p>
                  <form action="/cart/add-hosting" method="GET">
                    <input type="hidden" name="_token" :value="token" />

                    <input type="hidden" name="pid" :value="product.pid" />
                    <input type="hidden" name="domain" :value="domain.name" />
                    <input type="hidden" name="status" :value="domain.whois" />
                    <input type="hidden" name="price" :value="domain.prices[1]" />
                    <input type="hidden" name="gratis" value="" />
                    <input type="hidden" name="choose" value="modal-associate-hosting" />
                    <input type="hidden" name="arrayKey" :value="index" />
                    <input type="hidden" name="checkbox_domain[]" value="" />
                    <button type="submit" class="product-details">
                      Aggiungi
                    </button>
                  </form>
                </div>
                <div class="text-center">
                  <a :href="
                    `/piani-hosting/` + strSlug(product.name.toLowerCase())
                  " target="_blank" class="more-info-modal" style="font-size: 14px">maggiori informazioni</a>
                </div>
                <span class="gradient-piece-after"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="gradient-piece-after-modal"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AssociateHosting",
  props: [
    "index",
    "productGroups",
    "prodotti",
    "domain",
    "itemsPerRow",
    "token",
  ],
  methods: {
    trim(str, charlist) {
      //   example 1: trim('    Kevin van Zonneveld    ')
      //   returns 1: 'Kevin van Zonneveld'
      //   example 2: trim('Hello World', 'Hdle')
      //   returns 2: 'o Wor'
      //   example 3: trim(16, 1)
      //   returns 3: '6'

      let whitespace = [
        " ",
        "\n",
        "\r",
        "\t",
        "\f",
        "\x0b",
        "\xa0",
        "\u2000",
        "\u2001",
        "\u2002",
        "\u2003",
        "\u2004",
        "\u2005",
        "\u2006",
        "\u2007",
        "\u2008",
        "\u2009",
        "\u200a",
        "\u200b",
        "\u2028",
        "\u2029",
        "\u3000",
      ].join("");
      str += "";

      if (charlist) {
        whitespace = (charlist + "").replace(
          /([\[\]\(\)\.\?\/\*\{\}\+\$\^:])/g,
          "$1"
        );
      }

      let l = str.length;
      let i;
      for (i = 0; i < l; i++) {
        if (whitespace.indexOf(str.charAt(i)) === -1) {
          str = str.substring(i);
          break;
        }
      }

      l = str.length;
      for (i = l - 1; i >= 0; i--) {
        if (whitespace.indexOf(str.charAt(i)) === -1) {
          str = str.substring(0, i + 1);
          break;
        }
      }

      return whitespace.indexOf(str.charAt(0)) === -1 ? str : "";
    },
    pregQuote(str, delimiter) {
      //   example 1: pregQuote("$40")
      //   returns 1: '\\$40'
      //   example 2: pregQuote("*RRRING* Hello?")
      //   returns 2: '\\*RRRING\\* Hello\\?'
      //   example 3: pregQuote("\\.+*?[^]$(){}=!<>|:")
      //   returns 3: '\\\\\\.\\+\\*\\?\\[\\^\\]\\$\\(\\)\\{\\}\\=\\!\\<\\>\\|\\:'

      return (str + "").replace(
        new RegExp(
          "[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\" + (delimiter || "") + "-]",
          "g"
        ),
        "\\$&"
      );
    },
    strSlug($title, $separator = "-") {
      if (!$title) return "";
      $title = $title.toLocaleLowerCase();
      const charsArray = {
        0: ["°", "₀", "۰"],
        1: ["¹", "₁", "۱"],
        2: ["²", "₂", "۲"],
        3: ["³", "₃", "۳"],
        4: ["⁴", "₄", "۴", "٤"],
        5: ["⁵", "₅", "۵", "٥"],
        6: ["⁶", "₆", "۶", "٦"],
        7: ["⁷", "₇", "۷"],
        8: ["⁸", "₈", "۸"],
        9: ["⁹", "₉", "۹"],
        a: [
          "à",
          "á",
          "ả",
          "ã",
          "ạ",
          "ă",
          "ắ",
          "ằ",
          "ẳ",
          "ẵ",
          "ặ",
          "â",
          "ấ",
          "ầ",
          "ẩ",
          "ẫ",
          "ậ",
          "ā",
          "ą",
          "å",
          "α",
          "ά",
          "ἀ",
          "ἁ",
          "ἂ",
          "ἃ",
          "ἄ",
          "ἅ",
          "ἆ",
          "ἇ",
          "ᾀ",
          "ᾁ",
          "ᾂ",
          "ᾃ",
          "ᾄ",
          "ᾅ",
          "ᾆ",
          "ᾇ",
          "ὰ",
          "ά",
          "ᾰ",
          "ᾱ",
          "ᾲ",
          "ᾳ",
          "ᾴ",
          "ᾶ",
          "ᾷ",
          "а",
          "أ",
          "အ",
          "ာ",
          "ါ",
          "ǻ",
          "ǎ",
          "ª",
          "ა",
          "अ",
          "ا",
        ],
        b: ["б", "β", "Ъ", "Ь", "ب", "ဗ", "ბ"],
        c: ["ç", "ć", "č", "ĉ", "ċ"],
        d: [
          "ď",
          "ð",
          "đ",
          "ƌ",
          "ȡ",
          "ɖ",
          "ɗ",
          "ᵭ",
          "ᶁ",
          "ᶑ",
          "д",
          "δ",
          "د",
          "ض",
          "ဍ",
          "ဒ",
          "დ",
        ],
        e: [
          "é",
          "è",
          "ẻ",
          "ẽ",
          "ẹ",
          "ê",
          "ế",
          "ề",
          "ể",
          "ễ",
          "ệ",
          "ë",
          "ē",
          "ę",
          "ě",
          "ĕ",
          "ė",
          "ε",
          "έ",
          "ἐ",
          "ἑ",
          "ἒ",
          "ἓ",
          "ἔ",
          "ἕ",
          "ὲ",
          "έ",
          "е",
          "ё",
          "э",
          "є",
          "ə",
          "ဧ",
          "ေ",
          "ဲ",
          "ე",
          "ए",
          "إ",
          "ئ",
        ],
        f: ["ф", "φ", "ف", "ƒ", "ფ"],
        g: ["ĝ", "ğ", "ġ", "ģ", "г", "ґ", "γ", "ဂ", "გ", "گ"],
        h: ["ĥ", "ħ", "η", "ή", "ح", "ه", "ဟ", "ှ", "ჰ"],
        i: [
          "í",
          "ì",
          "ỉ",
          "ĩ",
          "ị",
          "î",
          "ï",
          "ī",
          "ĭ",
          "į",
          "ı",
          "ι",
          "ί",
          "ϊ",
          "ΐ",
          "ἰ",
          "ἱ",
          "ἲ",
          "ἳ",
          "ἴ",
          "ἵ",
          "ἶ",
          "ἷ",
          "ὶ",
          "ί",
          "ῐ",
          "ῑ",
          "ῒ",
          "ΐ",
          "ῖ",
          "ῗ",
          "і",
          "ї",
          "и",
          "ဣ",
          "ိ",
          "ီ",
          "ည်",
          "ǐ",
          "ი",
          "इ",
        ],
        j: ["ĵ", "ј", "Ј", "ჯ", "ج"],
        k: ["ķ", "ĸ", "к", "κ", "Ķ", "ق", "ك", "က", "კ", "ქ", "ک"],
        l: ["ł", "ľ", "ĺ", "ļ", "ŀ", "л", "λ", "ل", "လ", "ლ"],
        m: ["м", "μ", "م", "မ", "მ"],
        n: ["ñ", "ń", "ň", "ņ", "ŉ", "ŋ", "ν", "н", "ن", "န", "ნ"],
        o: [
          "ó",
          "ò",
          "ỏ",
          "õ",
          "ọ",
          "ô",
          "ố",
          "ồ",
          "ổ",
          "ỗ",
          "ộ",
          "ơ",
          "ớ",
          "ờ",
          "ở",
          "ỡ",
          "ợ",
          "ø",
          "ō",
          "ő",
          "ŏ",
          "ο",
          "ὀ",
          "ὁ",
          "ὂ",
          "ὃ",
          "ὄ",
          "ὅ",
          "ὸ",
          "ό",
          "о",
          "و",
          "θ",
          "ို",
          "ǒ",
          "ǿ",
          "º",
          "ო",
          "ओ",
        ],
        p: ["п", "π", "ပ", "პ", "پ"],
        q: ["ყ"],
        r: ["ŕ", "ř", "ŗ", "р", "ρ", "ر", "რ"],
        s: ["ś", "š", "ş", "с", "σ", "ș", "ς", "س", "ص", "စ", "ſ", "ს"],
        t: ["ť", "ţ", "т", "τ", "ț", "ت", "ط", "ဋ", "တ", "ŧ", "თ", "ტ"],
        u: [
          "ú",
          "ù",
          "ủ",
          "ũ",
          "ụ",
          "ư",
          "ứ",
          "ừ",
          "ử",
          "ữ",
          "ự",
          "û",
          "ū",
          "ů",
          "ű",
          "ŭ",
          "ų",
          "µ",
          "у",
          "ဉ",
          "ု",
          "ူ",
          "ǔ",
          "ǖ",
          "ǘ",
          "ǚ",
          "ǜ",
          "უ",
          "उ",
        ],
        v: ["в", "ვ", "ϐ"],
        w: ["ŵ", "ω", "ώ", "ဝ", "ွ"],
        x: ["χ", "ξ"],
        y: [
          "ý",
          "ỳ",
          "ỷ",
          "ỹ",
          "ỵ",
          "ÿ",
          "ŷ",
          "й",
          "ы",
          "υ",
          "ϋ",
          "ύ",
          "ΰ",
          "ي",
          "ယ",
        ],
        z: ["ź", "ž", "ż", "з", "ζ", "ز", "ဇ", "ზ"],
        aa: ["ع", "आ", "آ"],
        ae: ["ä", "æ", "ǽ"],
        ai: ["ऐ"],
        at: ["@"],
        ch: ["ч", "ჩ", "ჭ", "چ"],
        dj: ["ђ", "đ"],
        dz: ["џ", "ძ"],
        ei: ["ऍ"],
        gh: ["غ", "ღ"],
        ii: ["ई"],
        ij: ["ĳ"],
        kh: ["х", "خ", "ხ"],
        lj: ["љ"],
        nj: ["њ"],
        oe: ["ö", "œ", "ؤ"],
        oi: ["ऑ"],
        oii: ["ऒ"],
        ps: ["ψ"],
        sh: ["ш", "შ", "ش"],
        shch: ["щ"],
        ss: ["ß"],
        sx: ["ŝ"],
        th: ["þ", "ϑ", "ث", "ذ", "ظ"],
        ts: ["ц", "ც", "წ"],
        ue: ["ü"],
        uu: ["ऊ"],
        ya: ["я"],
        yu: ["ю"],
        zh: ["ж", "ჟ", "ژ"],
        "(c)": ["©"],
        A: [
          "Á",
          "À",
          "Ả",
          "Ã",
          "Ạ",
          "Ă",
          "Ắ",
          "Ằ",
          "Ẳ",
          "Ẵ",
          "Ặ",
          "Â",
          "Ấ",
          "Ầ",
          "Ẩ",
          "Ẫ",
          "Ậ",
          "Å",
          "Ā",
          "Ą",
          "Α",
          "Ά",
          "Ἀ",
          "Ἁ",
          "Ἂ",
          "Ἃ",
          "Ἄ",
          "Ἅ",
          "Ἆ",
          "Ἇ",
          "ᾈ",
          "ᾉ",
          "ᾊ",
          "ᾋ",
          "ᾌ",
          "ᾍ",
          "ᾎ",
          "ᾏ",
          "Ᾰ",
          "Ᾱ",
          "Ὰ",
          "Ά",
          "ᾼ",
          "А",
          "Ǻ",
          "Ǎ",
        ],
        B: ["Б", "Β", "ब"],
        C: ["Ç", "Ć", "Č", "Ĉ", "Ċ"],
        D: ["Ď", "Ð", "Đ", "Ɖ", "Ɗ", "Ƌ", "ᴅ", "ᴆ", "Д", "Δ"],
        E: [
          "É",
          "È",
          "Ẻ",
          "Ẽ",
          "Ẹ",
          "Ê",
          "Ế",
          "Ề",
          "Ể",
          "Ễ",
          "Ệ",
          "Ë",
          "Ē",
          "Ę",
          "Ě",
          "Ĕ",
          "Ė",
          "Ε",
          "Έ",
          "Ἐ",
          "Ἑ",
          "Ἒ",
          "Ἓ",
          "Ἔ",
          "Ἕ",
          "Έ",
          "Ὲ",
          "Е",
          "Ё",
          "Э",
          "Є",
          "Ə",
        ],
        F: ["Ф", "Φ"],
        G: ["Ğ", "Ġ", "Ģ", "Г", "Ґ", "Γ"],
        H: ["Η", "Ή", "Ħ"],
        I: [
          "Í",
          "Ì",
          "Ỉ",
          "Ĩ",
          "Ị",
          "Î",
          "Ï",
          "Ī",
          "Ĭ",
          "Į",
          "İ",
          "Ι",
          "Ί",
          "Ϊ",
          "Ἰ",
          "Ἱ",
          "Ἳ",
          "Ἴ",
          "Ἵ",
          "Ἶ",
          "Ἷ",
          "Ῐ",
          "Ῑ",
          "Ὶ",
          "Ί",
          "И",
          "І",
          "Ї",
          "Ǐ",
          "ϒ",
        ],
        K: ["К", "Κ"],
        L: ["Ĺ", "Ł", "Л", "Λ", "Ļ", "Ľ", "Ŀ", "ल"],
        M: ["М", "Μ"],
        N: ["Ń", "Ñ", "Ň", "Ņ", "Ŋ", "Н", "Ν"],
        O: [
          "Ó",
          "Ò",
          "Ỏ",
          "Õ",
          "Ọ",
          "Ô",
          "Ố",
          "Ồ",
          "Ổ",
          "Ỗ",
          "Ộ",
          "Ơ",
          "Ớ",
          "Ờ",
          "Ở",
          "Ỡ",
          "Ợ",
          "Ø",
          "Ō",
          "Ő",
          "Ŏ",
          "Ο",
          "Ό",
          "Ὀ",
          "Ὁ",
          "Ὂ",
          "Ὃ",
          "Ὄ",
          "Ὅ",
          "Ὸ",
          "Ό",
          "О",
          "Θ",
          "Ө",
          "Ǒ",
          "Ǿ",
        ],
        P: ["П", "Π"],
        R: ["Ř", "Ŕ", "Р", "Ρ", "Ŗ"],
        S: ["Ş", "Ŝ", "Ș", "Š", "Ś", "С", "Σ"],
        T: ["Ť", "Ţ", "Ŧ", "Ț", "Т", "Τ"],
        U: [
          "Ú",
          "Ù",
          "Ủ",
          "Ũ",
          "Ụ",
          "Ư",
          "Ứ",
          "Ừ",
          "Ử",
          "Ữ",
          "Ự",
          "Û",
          "Ū",
          "Ů",
          "Ű",
          "Ŭ",
          "Ų",
          "У",
          "Ǔ",
          "Ǖ",
          "Ǘ",
          "Ǚ",
          "Ǜ",
        ],
        V: ["В"],
        W: ["Ω", "Ώ", "Ŵ"],
        X: ["Χ", "Ξ"],
        Y: [
          "Ý",
          "Ỳ",
          "Ỷ",
          "Ỹ",
          "Ỵ",
          "Ÿ",
          "Ῠ",
          "Ῡ",
          "Ὺ",
          "Ύ",
          "Ы",
          "Й",
          "Υ",
          "Ϋ",
          "Ŷ",
        ],
        Z: ["Ź", "Ž", "Ż", "З", "Ζ"],
        AE: ["Ä", "Æ", "Ǽ"],
        CH: ["Ч"],
        DJ: ["Ђ"],
        DZ: ["Џ"],
        GX: ["Ĝ"],
        HX: ["Ĥ"],
        IJ: ["Ĳ"],
        JX: ["Ĵ"],
        KH: ["Х"],
        LJ: ["Љ"],
        NJ: ["Њ"],
        OE: ["Ö", "Œ"],
        PS: ["Ψ"],
        SH: ["Ш"],
        SHCH: ["Щ"],
        SS: ["ẞ"],
        TH: ["Þ"],
        TS: ["Ц"],
        UE: ["Ü"],
        YA: ["Я"],
        YU: ["Ю"],
        ZH: ["Ж"],
        " ": [
          "\xC2\xA0",
          "\xE2\x80\x80",
          "\xE2\x80\x81",
          "\xE2\x80\x82",
          "\xE2\x80\x83",
          "\xE2\x80\x84",
          "\xE2\x80\x85",
          "\xE2\x80\x86",
          "\xE2\x80\x87",
          "\xE2\x80\x88",
          "\xE2\x80\x89",
          "\xE2\x80\x8A",
          "\xE2\x80\xAF",
          "\xE2\x81\x9F",
          "\xE3\x80\x80",
        ],
      };
      for (let $key in charsArray) {
        $title = $title.replace(
          new RegExp(`${[].concat(charsArray[$key]).join("|")}`, "gi"),
          $key
        );
      }
      $title = $title.replace(/[^\x20-\x7E]/giu, "");
      // Convert all dashes/underscores into separator
      const $flip = $separator == "-" ? "_" : "-";
      $title = $title.replace(
        new RegExp("[" + this.pregQuote($flip) + "]+", "g"),
        $separator
      );
      // Remove all characters that are not the separator, letters, numbers, or whitespace.
      $title = $title.replace(
        new RegExp("[^" + this.pregQuote($separator) + "a-z0-9\\s]+", "gi"),
        $separator
      );

      // Replace all separator characters and whitespace by a single separator
      $title = $title.replace(
        new RegExp("[" + this.pregQuote($separator) + "\\s]+", "g"),
        $separator
      );

      return this.trim($title, $separator);
    },
  },
  mounted() { },
};
</script>
